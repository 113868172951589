import { KeycloakOnLoad } from 'keycloak-js';

export const environment = {
  production: true,
  api: 'https://www.mijnsportvisserij.nl/svnl/api',
  keycloak: {
    url: 'https://www.mijnsportvisserij.nl/auth',
    realm: 'Svnl',
    clientId: 'portal',
    initOptions: {
      onLoad: 'login-required' as KeycloakOnLoad,
      silentCheckSsoRedirectUri: '',
      redirectUri: '',
      checkLoginIframe: false,
    },
  },
  funnel: {
    url: 'https://www.vispas.nl/',
  },
  cancelDisabled: false,
  analytics: {
    ga: {
      enabled: true,
      code: 'UA-3799306-62',
    },
    gtm: {
      enabled: true,
      code: 'GTM-M9BHMJV',
    },
  },
};
