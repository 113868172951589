import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marker as extract } from '@shavenzov/ngx-translate-extract-marker';
import { HttpClient } from "@angular/common/http";
import { BaseService } from "@service/base.service";
import { Observable } from "rxjs";

class Language {
  code: string;
  label: string;
  image: string;
}

export class PreferredLanguage {
  code: string;
  name: string;
}

export const languageCodeList = ['nl', 'de', 'fr', 'pl', 'en'] as const;

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends BaseService {

  languages: Language[] = languageCodeList.map(code => ({
    code,
    label: `portal.language.country.${code}`,
    image: `/assets/icons/language/flag-${code}.png`
  }));

  private language = this.languages.find(lang => lang.code === 'nl');

  private readonly translateService = inject(TranslateService);

  constructor(
    httpClient: HttpClient,
  ) {
    super(httpClient, 'languages');

    languageCodeList.map(code => extract(`language.country.${code}`));

    this.translateService.addLangs([...languageCodeList]);
  }

  getLanguage(): Language {
    return this.language;
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  setLanguage(code: string): void {
    if (!code || !this.languages.find(lang => code === lang.code)) {
      console.error(`Cannot set language, code ${code} not found`);
    } else {
      this.language = this.languages[code];
      this.translateService.use(code).subscribe();
    }
  }

  getPreferredLanguages(): Observable<PreferredLanguage[]> {
    return this.httpClient.get<PreferredLanguage[]>(`${this.endpointUrl}`);
  }

}
